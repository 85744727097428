const projects = [
  {
    name: "SOL",
    validated: true,
    note: "-",
  },
  {
    name: "ETH",
    validated: true,
    note: "-",
  },
  {
    name: "BTC",
    validated: true,
    note: "-",
  },
  {
    name: "DOGE",
    validated: true,
    note: "-",
  },
];

export default projects;
