import React, { useState, useEffect } from "react";
import "./AlertsComponent.css";
import ProjectSentiment from "./ProjectSentiment";
import projects from "./projects";

const SimplifiedAlertsComponent = () => {
  const [marketSentiment, setMarketSentiment] = useState({});
  const [isSoundEnabled, setIsSoundEnabled] = useState(false);
  const [overallMarketSentiment, setOverallMarketSentiment] = useState(""); // State to hold overall market sentiment
  const [isRefreshEnabled, setIsRefreshEnabled] = useState(true); // State to track if auto-refresh is enabled

  const timeframes = ["1d", "4h", "1h", "15min", "5min", "1min"]; // Define the timeframes

  useEffect(() => {
    const fetchMarketSentiment = async () => {
      try {
        const response = await fetch(
          "https://tradebylogic.com/market_sentiment"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch market sentiment");
        }
        const data = await response.json();
        setMarketSentiment(data);

        // Determine overall market sentiment
        const bullishCount = Object.values(data).reduce(
          (total, timeframe) => total + timeframe.bullishCount,
          0
        );
        const bearishCount = Object.values(data).reduce(
          (total, timeframe) => total + timeframe.bearishCount,
          0
        );

        if (bullishCount > bearishCount) {
          setOverallMarketSentiment("Bullish");
        } else if (bearishCount > bullishCount) {
          setOverallMarketSentiment("Bearish");
        } else {
          setOverallMarketSentiment("Neutral");
        }

        // Fetch sentiment data for each project
        //     await Promise.all(
        //       projects.map(async (project) => {
        //         const projectResponse = await fetch(
        //           `https://tradebylogic.com/project_sentiment/${project}`
        //         );
        //         if (!projectResponse.ok) {
        //           throw new Error(`Failed to fetch ${project} sentiment`);
        //         }
        //         const projectData = await projectResponse.json();
        //         setMarketSentiment((prevState) => ({
        //           ...prevState,
        //           [project]: projectData,
        //         }));
        //       })
        //     );
        //   } catch (error) {
        //     console.error("Error fetching data:", error);
        //   }
        // };

        // Fetch sentiment data for each project
        await Promise.all(
          projects
            .filter((project) => project.validated)
            .map(async (project) => {
              const projectResponse = await fetch(
                `https://tradebylogic.com/project_sentiment/${project.name}`
              );
              if (!projectResponse.ok) {
                throw new Error(`Failed to fetch ${project.name} sentiment`);
              }
              const projectData = await projectResponse.json();
              setMarketSentiment((prevState) => ({
                ...prevState,
                [project.name]: projectData,
              }));
            })
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (isRefreshEnabled) {
      fetchMarketSentiment(); // Fetch data when auto-refresh is enabled
      const intervalId = setInterval(fetchMarketSentiment, 30000); // Refresh every 30 seconds

      return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }
  }, [isRefreshEnabled]); // Re-run effect when isRefreshEnabled changes

  const toggleSound = () => {
    setIsSoundEnabled(!isSoundEnabled);
  };

  const toggleRefresh = () => {
    setIsRefreshEnabled(!isRefreshEnabled);
  };

  const renderTimeframeCircle = (timeframe) => {
    if (marketSentiment && marketSentiment[timeframe]) {
      const { bullishCount, bearishCount, overallSentiment } =
        marketSentiment[timeframe];
      let color;
      if (overallSentiment === "Bullish") {
        color = "green";
      } else if (overallSentiment === "Bearish") {
        color = "red";
      } else {
        color = "grey"; // Set color to grey when sentiment is neutral
      }
      return (
        <div
          key={timeframe}
          className={`circle ${
            overallSentiment === "Bullish"
              ? "glow-green"
              : overallSentiment === "Bearish"
              ? "glow-red"
              : ""
          }`}
          style={{ backgroundColor: color }}
        >
          <div className="label">{timeframe}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <div style={{ textAlign: "center" }}>
        <p>Overall Market Sentiment: {overallMarketSentiment}</p>
        <br></br>
      </div>
      <div className="circles-container">
        {timeframes.map((timeframe) => renderTimeframeCircle(timeframe))}
      </div>
      <br></br>
      {/* <div style={{ textAlign: "center" }}>
        <button
          onClick={toggleRefresh}
          style={{
            backgroundColor: "#000",
            borderStyle: "solid",
            borderColor: "#222",
            color: "#ccc",
            padding: "10px",
          }}
        >
          {isRefreshEnabled ? "Stop Auto Refresh" : "Start Auto Refresh"}
        </button>
      </div> */}

      <div>
        {projects
          .filter((project) => project.validated) // Filter out non-validated projects
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort projects in ascending order
          .map((project) => (
            <div key={project.name} style={{ textAlign: "center" }}>
              <ProjectSentiment
                key={project.name}
                projectName={project.name}
                overallMarketSentiment={overallMarketSentiment}
                note={project.note}
              />
            </div>
          ))}
        <br />
        <center style={{ color: "#ffa500" }}>
          I am speculating, this is not financial advice.
        </center>
      </div>
    </div>
  );
};

export default SimplifiedAlertsComponent;
