import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const ProjectSentimentComponent = ({
  projectName,
  overallMarketSentiment,
  note,
}) => {
  const [projectSentiment, setProjectSentiment] = useState({});

  useEffect(() => {
    const fetchProjectSentiment = async () => {
      try {
        const response = await fetch(
          `https://tradebylogic.com/project_sentiment/${projectName}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch ${projectName} sentiment`);
        }
        const data = await response.json();
        setProjectSentiment(data);
      } catch (error) {
        console.error(`Error fetching ${projectName} sentiment:`, error);
      }
    };
    fetchProjectSentiment();
  }, [projectName]);

  const calculateAverageEntryPrice = () => {
    let totalEntryPrice = 0;
    let numEntries = 0;

    if (projectSentiment && projectSentiment.details) {
      Object.values(projectSentiment.details).forEach((detail) => {
        if (detail.entry) {
          totalEntryPrice += parseFloat(detail.entry);
          numEntries++;
        }
      });
    }

    const averagePrice = numEntries > 0 ? totalEntryPrice / numEntries : 0;
    return averagePrice.toFixed(8); // Format the average price up to 8 decimals
  };

  const renderTimeframeCircle = (timeframe) => {
    if (
      projectSentiment &&
      projectSentiment.details &&
      projectSentiment.details[timeframe]
    ) {
      const { direction } = projectSentiment.details[timeframe];
      const isBullish = direction === "Long";
      const isOverallBullish = overallMarketSentiment === "Bullish";
      const isOverallBearish = overallMarketSentiment === "Bearish";

      let glowClass = "";
      let borderColor = "#222"; // Default border color

      // Apply glow only if sentiment matches overall market sentiment
      if ((isBullish && isOverallBullish) || (!isBullish && isOverallBearish)) {
        glowClass = isBullish ? "glow-border-green" : "glow-border-red";
        borderColor = "transparent"; // Remove default border when glowing
      }

      const color = direction === "Long" ? "green" : "red";

      return (
        <div
          key={timeframe}
          className={`circle ${glowClass}`}
          style={{
            backgroundColor: color,
            borderColor: borderColor,
            borderStyle: "solid",
            borderWidth: "2px",
          }}
        >
          <div className="label">{timeframe}</div>
        </div>
      );
    } else {
      // No signal or neutral sentiment
      return (
        <div
          key={timeframe}
          className="circle"
          style={{
            backgroundColor: "grey",
            borderColor: "#222",
            borderStyle: "solid",
            borderWidth: "2px",
          }}
        >
          <div className="label">{timeframe}</div>
        </div>
      );
    }
  };

  return (
    <div
      className={`project-sentiment-container ${
        projectSentiment.overallSentiment === "Bullish" &&
        overallMarketSentiment === "Bullish"
          ? "glow-border-green"
          : projectSentiment.overallSentiment === "Bearish" &&
            overallMarketSentiment === "Bearish"
          ? "glow-border-red"
          : ""
      }`}
      style={{
        padding: "1rem",
        margin: "1rem",
        borderStyle: "solid",
        borderColor:
          projectSentiment.overallSentiment === overallMarketSentiment
            ? "transparent"
            : "#222", // Only apply #222 when sentiments do not match
        borderWidth: "2px",
      }}
    >
      <h1>{projectName} Sentiment</h1>
      <div className="circles-container">
        {["1d", "4h", "1h", "15min", "5min", "1min"].map((timeframe) =>
          renderTimeframeCircle(timeframe)
        )}
      </div>
      <div
        style={{
          background: "#111",
          color: "#fff",
          padding: "5px",
          fontFamily: "Arial, sans-serif",
          fontSize: "14px",
          textAlign: "left",
        }}
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            textAlign: "left",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  border: "1px solid #444",
                  padding: "4px",
                  textAlign: "left",
                }}
              >
                Overall Sentiment:
              </td>
              <td
                style={{
                  border: "1px solid #444",
                  padding: "4px",
                  textAlign: "left",
                }}
              >
                {projectSentiment.overallSentiment}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #444",
                  padding: "4px",
                  textAlign: "left",
                }}
              >
                Average Entry Price:
              </td>
              <td
                style={{
                  border: "1px solid #444",
                  padding: "4px",
                  textAlign: "left",
                }}
              >
                {calculateAverageEntryPrice()}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #444",
                  padding: "4px",
                  textAlign: "left",
                }}
              >
                Note:
              </td>
              <td
                style={{
                  border: "1px solid #444",
                  padding: "4px",
                  textAlign: "left",
                }}
              >
                {note}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

ProjectSentimentComponent.propTypes = {
  projectName: PropTypes.string.isRequired,
};

export default ProjectSentimentComponent;
